
import CondicionesPrivacidadModal from "@/components/modals/CondicionesPrivacidad.vue";
import localidades from "@/core/data/localidades";
import provincias from "@/core/data/provinciasEP";
import CursosModule from "@/store/modules/CursosModule";
import InscripcionModule from "@/store/modules/InscripcionModule";
import Contacto from "@/views/ContactoContent.vue";
import FAQs from "@/views/FAQsContent.vue";
import Intro from "@/views/IntroContent.vue";
import "moment/locale/es";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute, useRouter } from "vue-router";
import { Vue3Lottie } from "vue3-lottie";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface InscripcionCreate {
  nombre: string;
  apellidos: string;
  dni: string;
  telefono: string;
  email: string;
  provincia: string;
  localidad: string;
  codigoPostal: string;
  perfil: string;
  nombreEmpresa?: string | null;
  localidadEmpresa?: string | null;
  modalidad: string;
  politicaPrivacidad: boolean;
}
interface Localidad {
  label: string;
  value: string;
}

export const useUsername = ({ user }) => {
  return { username: user.name };
};

export default defineComponent({
  name: "kt-landing",
  components: {
    Intro,
    FAQs,
    Contacto,
    Vue3Lottie,
    CondicionesPrivacidadModal,
    ErrorMessage,
  },

  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const token = route.query.act;
    const router = useRouter();

    const store = useStore();
    const module = getModule(CursosModule, store);
    const moduleInscripciones = getModule(InscripcionModule, store);

    const loadingCursos = ref(false);
    const errorCargandoCursos = ref(false);
    const crearInscripcion = ref(false);

    onBeforeMount(() => {
      if (token) {
        loadingCursos.value = true;
        module.fetchById({ formularioId: token as string }).then(function () {
          if (module.errors) {
            errorCargandoCursos.value = true;
          }
          loadingCursos.value = false;
        });
      } else {
        errorCargandoCursos.value = false;
        loadingCursos.value = true;
        module.fetch().then(function () {
          if (module.errors) {
            errorCargandoCursos.value = true;
          }
          loadingCursos.value = false;
        });
      }
    });
    const datosCursos = computed(() => module.cursos);
    const datosCurso = computed(() => module.curso);

    watch(
      () => route.fullPath,
      (newFullPath) => {
        const formularioId = newFullPath.match(/act=([^&]*)/)?.[1];
        if (!formularioId) {
          module.setDetailData(null);
          module.fetch();
        }
      }
    );

    watch(datosCurso, () => {
      if (!datosCurso.value) return;

      resetForm({
        values: {
          nombre: "",
          apellidos: "",
          dni: "",
          telefono: "",
          email: "",
          provincia: "",
          localidad: "",
          codigoPostal: "",
          perfil: "PARTICULAR",
          nombreEmpresa: "",
          localidadEmpresa: "",
          modalidad: "VIDEOCONFERENCIA",
          politicaPrivacidad: false,
        },
      });
    });

    const schema = Yup.object({
      nombre: Yup.string().required("Nombre es obligatorio"),
      apellidos: Yup.string().required("Apellidos es obligatorio"),
      dni: Yup.string().required("DNI es obligatorio"),
      email: Yup.string().required("Email es obligatorio"),
      telefono: Yup.string().required("Teléfono es obligatorio"),
      localidad: Yup.string().required("Localidad es obligatorio"),
      provincia: Yup.string().required("Provincia es obligatorio"),
      codigoPostal: Yup.string().required("Código postal es obligatorio"),
      modalidad: Yup.string().required("Modalidad es obligatoria"),
      perfil: Yup.string().required(),
      politicaPrivacidad: Yup.boolean()
        .required()
        .isTrue("Es obligatorio aceptar la política de privacidad"),
      nombreEmpresa: Yup.string()
        .ensure()
        .when("perfil", {
          is: "EMPRESA",
          then: Yup.string()
            .required()
            .label("Nombre de empresa es obligatorio"),
        }),
      localidadEmpresa: Yup.string()
        .ensure()
        .when("perfil", {
          is: "EMPRESA",
          then: Yup.string()
            .required()
            .label("Localidad de la empresa es obligatorio"),
        }),
    });

    const { resetForm, handleSubmit, errors } = useForm<InscripcionCreate>({
      validationSchema: schema,
    });

    const { value: nombre } = useField("nombre");
    const { value: apellidos } = useField("apellidos");
    const { value: dni } = useField("dni");
    const { value: telefono } = useField("telefono");
    const { value: email } = useField("email");
    const { value: provincia } = useField("provincia");
    const { value: localidad } = useField("localidad");
    const { value: codigoPostal } = useField<number>("codigoPostal");
    const { value: perfil } = useField("perfil");
    const { value: nombreEmpresa } = useField("nombreEmpresa");
    const { value: localidadEmpresa } = useField("localidadEmpresa");
    const { value: modalidad } = useField("modalidad");
    const { value: politicaPrivacidad } = useField("politicaPrivacidad");

    const i18n = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    let localidadesTodas = ref<Localidad[]>([]);
    localidadesTodas.value = localidades
      .map((l) => {
        return { label: l.localidad, value: l.localidad };
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

    let localidadesFiltradas = ref<Localidad[]>([]);
    localidadesFiltradas.value = localidades
      .filter((loc) => loc.provincia === (provincia.value as string))
      .map((l) => {
        return { label: l.localidad, value: l.localidad };
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

    const updateLocalidades = () => {
      localidad.value = "";
      return (localidadesFiltradas.value = localidades
        .filter((loc) => loc.provincia === provincia.value)
        .map((l) => {
          return { label: l.localidad, value: l.localidad };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        }));
    };
    return {
      i18n,
      errors,
      token,
      errorCargandoCursos,
      loadingCursos,
      datosCurso,
      datosCursos,
      nombre,
      apellidos,
      dni,
      telefono,
      email,
      provincia,
      localidad,
      codigoPostal,
      modalidad,
      perfil,
      nombreEmpresa,
      localidadEmpresa,
      politicaPrivacidad,
      provincias,
      localidades,
      localidadesFiltradas,
      localidadesTodas,
      crearInscripcion,
      updateLocalidades,
      guardar: handleSubmit(async (values) => {
        const cursoId = datosCurso.value?.id as string;

        if (!cursoId) return;

        let videoconferencia = false;
        let presencial = false;

        if (
          datosCurso.value?.videoconferencia &&
          datosCurso.value?.presencial
        ) {
          if (values.modalidad === "VIDEOCONFERENCIA") {
            videoconferencia = true;
          } else if (values.modalidad === "PRESENCIAL") {
            presencial = true;
          }
        } else if (datosCurso.value?.videoconferencia) {
          videoconferencia = true;
        } else if (datosCurso.value?.presencial) {
          presencial = true;
        }

        crearInscripcion.value = true;
        try {
          await moduleInscripciones
            .crear({
              inscripcion: {
                nombre: values.nombre,
                apellidos: values.apellidos,
                dni: values.dni,
                email: values.email,
                telefono: values.telefono,
                localidad: values.localidad,
                provincia: values.provincia,
                codigoPostal: parseInt(
                  values.codigoPostal as unknown as string
                ),
                perfil: values.perfil,
                nombreEmpresa: values.nombreEmpresa ?? "",
                localidadEmpresa: values.localidadEmpresa ?? "",
                videoconferencia: videoconferencia,
                presencial: presencial,
                estado: "INSCRITO",
                cursoId: cursoId,
              },
            })
            .then(() => {
              Swal.fire({
                text: t("formularios.inscripcionCursoDetalle.crear.exito"),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("dialogs.okButton"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                module.fetch();
                router.push("/home");
              });
            });
        } catch (err) {
          Swal.fire({
            text: t("formularios.inscripcionCursoDetalle.crear.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          crearInscripcion.value = false;
        }
      }),
      onItemTap: async (curso) => {
        module.setDetailData(curso);
        window.location.href =
          window.location.origin + "/#/home?act=" + curso.formularioId;
      },
    };
  },
});
